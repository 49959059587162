<template>
  <div class="feed-body">
    <div class="feed-contents">
      <v-date-picker
        v-model="picker"
        full-width
        class="date-picker"
        :class="$vuetify.breakpoint.xs ? 'mb-3' : 'mb-5'"
      />
      <agency-need-card
        v-for="need in needs"
        :key="need._id"
        :need="need"
        style="width: 100%"
        class="mb-3"
      />
      <div v-if="needs.length == 0" class="mt-6">No needs</div>
      <v-progress-circular indeterminate color="amber" v-if="loading" />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import dateFormat from "dateformat";
import AgencyNeedCard from "../agency/needs/AgencyNeedCard.vue";
export default {
  components: { AgencyNeedCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      picker: null,
      needs: [],
    };
  },
  methods: {
    ...mapActions("need", {
      getDateNeeds: "fetchDateNeeds",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreNotifications() {
      this.loadingMore = true;
      this.loadMore({ skip: this.notifications.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    loadNeeds() {
      this.loading = true;
      this.getDateNeeds(this.picker)
        .then((res) => {
          this.loading = false;
          this.needs = res;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            this.showErrorMessage(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
  },
  watch: {
    picker(value) {
      if (value) {
        this.loadNeeds();
      }
    },
  },
  mounted() {
    var date = new Date();
    this.picker = dateFormat(date, "yyyy-mm-dd");
    this.loadNeeds();
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.date-picker >>> .v-picker__title {
  background: #cde539 !important;
  color: #242f36;
  font-weight: 700;
  font-family: Poppins-Bold;
}
</style>
